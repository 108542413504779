import React, {useState} from 'react';
import {db} from './firebase';

function ChatInputBox({user, channelId}) {
  
  return (
    <form onSubmit={event=>{
      event.preventDefault();

      const msg = event.target.elements[0].value;
      // or CHANNELS/general/MESSAGES
      db.collection('CHANNELS')
        .doc(channelId)
        .collection('MESSAGES')
        .add({
          text: msg,
          createdAt: new Date(),
          user     : db.collection('USERS').doc(user.uid)
        });
      
      event.target.reset();
    }} className="ChatInputBox">
      <input className="ChatInput" placeholder="Message #general" />
    </form>
  );
}

export default ChatInputBox;
