import React, {useState, useEffect} from 'react';
import {Router, Redirect}           from '@reach/router'

import Nav            from './Nav';
import Channel        from './Channel';

function Chatbox({user}) {
  console.log('chatbox', user)
  return (
    <div className="App">
      <Nav user={user}/>
      <Router>
        <Channel path="channel/:channelId" user={user}/>
        <Redirect from="/" to="channel/general"/>
      </Router>
    </div>
  );
}

export default Chatbox;
