import React from 'react';
import {Link} from '@reach/router'

import {firebase} from './firebase'

import useCollection from './useCollection';

function Nav({user}) {
  const channels = useCollection('CHANNELS');

  return (
    <div className="Nav">
      <div className="User">
        <img
          className="UserImage"
          alt="whatever"
          src={user.photoUrl}
        />
        <div>
          <div>{user.displayName}</div>
          <div>
            <button onClick={()=>{
              firebase.auth().signOut();
            }} className="text-button">log out</button>
          </div>
        </div>
      </div>
      <nav className="ChannelNav">
        {channels.map(channel => (
          <Link key={channel.id} to={`/chatbox/channel/${channel.id}`}># {channel.id}</Link>
        ))}
      </nav>
    </div>
  );
}

export default Nav;
