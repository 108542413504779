import React, {useEffect} from 'react';

import {db} from './firebase'
import Members from './Members';
import ChannelInfo from './ChannelInfo';
import Messages from './Messages';
import ChatInputBox from './ChatInputBox';

function Channel({user, channelId}) {

  useEffect(()=>{
    db.doc(`USERS/${user.uid}`).update({
      [`channels.${channelId}`] : true
    });
  },[user.uid, channelId]);

  return (
    <div className="Channel">
      <div className="ChannelMain">
        <ChannelInfo channelId={channelId}/>
        <Messages channelId={channelId}/>
        <ChatInputBox user={user} channelId={channelId}/>
      </div>
      <Members channelId={channelId} />
    </div>
  );
}

export default Channel;
