import React, { useState, useEffect } from "react";
import {
  Container,
  Col,
  Row,
  Form,
  Navbar,
  Nav,
  Table,
  Button,
} from "react-bootstrap";

import "bootstrap/dist/css/bootstrap.min.css";
const exercises = {
  bodyweight: {
    press: ["Pushups", "Handstands"],
    hinge: ["Glute Bridge", "Hip Extensions"],
    pull: ["Pull Up", "1/4 Lever"],
    squat: ["Pistol Squat", "Elevated Split Squat"],
    core: ["Plank", "Hollow Hold"],
  },
};

function getWorkout(exer) {
  const workout = [];

  workout.push(getRandomExercise(exer.press));
  workout.push(getRandomExercise(exer.hinge));
  workout.push(getRandomExercise(exer.pull));
  workout.push(getRandomExercise(exer.squat));
  workout.push(getRandomExercise(exer.core));

  return workout;
}

function getRandomExercise(exerArr) {
  return exerArr[Math.floor(Math.random() * exerArr.length)];
}

function Training() {
  const [active, setActive] = useState(false);
  const [seconds, setSeconds] = useState(0);
  const [rounds, setRounds] = useState(1);
  const [workout, setWorkout] = useState([]);
  const [exerciseInd, setExerciseInd] = useState(-1);

  const [timer, setTimer] = useState(60);
  const [pause, setPause] = useState(false);

  useEffect(() => {
    if (active) {
      const id = setInterval(() => {
        if (!pause) {
          setSeconds((seconds) => {
            return seconds + 1;
          });

          setTimer((timer) => {
            if (timer > 0) {
              return timer - 1;
            } else {
              setExerciseInd((exerciseInd) => {
                if (exerciseInd >= 4) {
                  setRounds((rounds) => {
                    return rounds + 1;
                  });
                  return 0;
                } else {
                  return exerciseInd + 1;
                }
              });
              return 60;
            }
          });
        }
      }, 1000);
      return () => clearInterval(id);
    }
  }, [active, pause]);

  useEffect(() => {
    setWorkout(getWorkout(exercises.bodyweight));
  }, []);

  function startWorkout() {
    setExerciseInd(exerciseInd + 1);
    setActive(true);
  }

  return (
    <Container
      fluid
      style={{
        "background-color": timer > 30 ? "#D2F28D" : "#1A3540",
        height: "100vh",
      }}
    >
      <Row
        style={{ "padding-top": "100px" }}
        className="justify-content-md-center"
      >
        <Col md="auto">
          {exerciseInd == -1 && (
            <Button
              variant="info"
              size="lg"
              onClick={() => {
                startWorkout();
              }}
            >
              Start
            </Button>
          )}
          {exerciseInd > -1 && (
            <div>
              <h1
                style={{
                  "font-size": "120px",
                  color: timer > 30 ? "#121726" : "#D2F28D",
                  "font-weight": "bold",
                }}
              >
                {timer > 30 ? workout[exerciseInd] : "Rest"}
              </h1>

              {/* <Button onClick={()=>{setExerciseInd(exerciseInd+1)}}>Next</Button> */}
            </div>
          )}
        </Col>
      </Row>
      <Row className="justify-content-md-center">
        <Col md={8}>
          <hr
            style={{
              "background-color": timer > 30 ? "#121726" : "#D2F28D",
              border: "none",
              height: "1px",
            }}
          />
        </Col>
      </Row>

      <Row className="justify-content-md-center">
        <Col md="auto">
          <h2
            style={{
              "font-size": "80px",
              color: timer > 30 ? "#121726" : "#D2F28D",
              width: "375px",
            }}
          >
            {new Date(timer * 1000).toISOString().substr(11, 8)}
          </h2>
        </Col>
      </Row>
      {(!active || pause || timer <= 30) && (
        <Row>
          <Col style={{ color: timer > 30 ? "#121726" : "#D2F28D" }}>
            {workout.map((exercise) => {
              return <div>{exercise}</div>;
            })}
          </Col>
        </Row>
      )}
      <Navbar fixed="bottom">
        <Navbar.Brand style={{ color: timer > 30 ? "#121726" : "#D2F28D" }}>
          {new Date(seconds * 1000).toISOString().substr(11, 8)}
        </Navbar.Brand>

        <Navbar.Collapse className="justify-content-end">
          <Button
            variant="outline-secondary"
            onClick={() => {
              setPause((pause) => {
                return !pause;
              });
            }}
          >
            {!pause ? "Pause" : "Play"}
          </Button>
          <Navbar.Text
            style={{
              "margin-left": "15px",
              color: timer > 30 ? "#121726" : "#D2F28D",
            }}
          >
            Round {rounds} / 5
          </Navbar.Text>
        </Navbar.Collapse>
      </Navbar>
    </Container>
  );
}

export default Training;
