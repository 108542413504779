import {useState, useEffect} from 'react'
import {db} from './firebase'

const cache        = {};
const pendingCache = {};

export default function useDoc(path) {
  const[doc, setDoc] = useState(cache[path]);

  useEffect(()=>{
    if(doc) {
      return;
    }
    let isCurrent = true;
    const pending = pendingCache[path];
    const promise = pending || (pendingCache[path] = db.doc(path).get());
    
    promise.then(doc => {
        if(isCurrent) {
          const cacheDoc = {
            ...doc.data(),
            id: doc.id
          };
          
          setDoc(cacheDoc);
          cache[path] = cacheDoc;
        }
      });
    
    return () =>{
      isCurrent = false;
    }
  }, [path]);

  return doc;
}