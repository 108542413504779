import React, {useState, useEffect} from 'react';
import {Router, Link}           from '@reach/router'

import {
  Container,
  Col,
  Row,
  Form,
  Navbar,
  Nav,
  Table
} from 'react-bootstrap'

import 'bootstrap/dist/css/bootstrap.min.css';

import {firebase} from '../chatbox/firebase'

import bugs from './bugs';
import fish from './fish';

function Nookipedia() {
  const [filterText, setFilterText] = useState('');
  const [filterFish, setFilterFish] = useState(true);
  const [filterBug, setFilterBug]   = useState(true);
  const [filterMonth, setFilterMonth] = useState((new Date()).toLocaleString('default', { month: 'short' }));
  return (
    <div>
      <header>
        <Navbar bg='dark' variant='dark' expand='lg'>
          <Navbar.Brand>Nookipedia</Navbar.Brand>
          {/* <Nav.Link><Link to="fish">Fish</Link></Nav.Link>
          <Nav.Link><Link to="bugs">Bugs</Link></Nav.Link>
          <Navbar.Collapse className="justify-content-end">
            <Navbar.Text>
              {user.displayName}
            </Navbar.Text>
            <Nav.Link onClick={()=>{
              firebase.auth().signOut();
            }}>Logout</Nav.Link>
          </Navbar.Collapse> */}
        </Navbar>
      </header>
      <main>
        <Container style={{'margin-top': '50px'}}>
          <Row>
            <Col>
              <Form>
                <Form.Row>
                  <Col>
                    <Form.Group controlId="filterText">
                      <Form.Control type="text" placeholder="Search by name" defaultValue={filterText} onChange={(evt)=>{
                          setFilterText(evt.target.value);
                      }}/>
                    </Form.Group>
                  </Col>
                </Form.Row>
                <Form.Row>
                  <Col  sm={2}>
                    <Form.Group controlId="filterType">
                      <Form.Check 
                        type="switch"
                        id="filter-type-fish"
                        label="Fish"
                        defaultChecked={filterFish}
                        onChange={(evt)=>{
                          setFilterFish(!filterFish)
                        }}
                      />
                      <Form.Check 
                        type="switch"
                        id="filter-type-bugs"
                        label="Bugs"
                        defaultChecked={filterBug}
                        onChange={(evt)=>{
                          setFilterBug(!filterBug)
                        }}
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={4}>
                    <Form.Group as={Row} controlId="filterMonth">
                      <Form.Label column sm={2}>Month</Form.Label>
                      <Col>
                        <Form.Control as="select" column sm={10} 
                          defaultValue={filterMonth} 
                          onChange={(evt)=>{
                            setFilterMonth(evt.target.value)
                        }}> 
                          <option>Jan</option>
                          <option>Feb</option>
                          <option>Mar</option>
                          <option>Apr</option>
                          <option>May</option>
                          <option>Jun</option>
                          <option>Jul</option>
                          <option>Aug</option>
                          <option>Sep</option>
                          <option>Oct</option>
                          <option>Nov</option>
                          <option>Dec</option>
                        </Form.Control>
                      </Col>
                    </Form.Group>
                  </Col>
                </Form.Row>
              </Form>
            </Col>
          </Row>
          <Row>
            <Col>
              <NookResults
                rows={
                  (filterFish ? (fish
                    .filter((aFish)=> {
                      return aFish.name.toLowerCase().indexOf(filterText.toLowerCase()) > -1;
                  })) : []).concat((filterBug ?
                    (bugs
                    .filter((bug)=> {
                      return bug.name.toLowerCase().indexOf(filterText.toLowerCase()) > -1;
                    })) : []
                  ))
                }

                filterMonth={filterMonth}
              />
              {/* <NookResults rows={fish
                .filter((aFish)=> {
                  return aFish.name.toLowerCase().indexOf(filterText.toLowerCase()) > -1;
                })} />e
              <NookResults rows={bugs
                .filter((bug)=> {
                  return bug.name.toLowerCase().indexOf(filterText.toLowerCase()) > -1;
                })} /> */}
            </Col>
          </Row>
        </Container>
      </main>
    </div>
  );
}

function NookResults({rows, filterMonth}) {
  return (
    <div>
      <Table striped bordered>
        <thead>
          <tr>
            <th>Name</th>
            <th>Price</th>
            <th>Start Time</th>
            <th>End Time</th>
            <th>Location</th>
            <th>Shadow</th>
            <th>Available</th>
            <th>Type</th>
          </tr>
        </thead>
        <tbody>
          {
            rows
            .map((doc,i)=>{
              return (
                <tr key={`${doc.type}_${doc.number}`}>
                  <td>{doc.name}</td>
                  <td>{doc.sell}</td>
                  <td>{doc.start}</td>
                  <td>{doc.end}</td>
                  <td>{doc.where}</td>
                  <td>{(doc.shadow ? doc.shadow : '-')}</td>
                  <td>{doc[filterMonth.toLowerCase()] == 'Y' ? 'Yes' : 'No'}</td>
                  <td>{doc.type}</td>
                </tr>
              )
            })
          }
        </tbody>
      </Table>
    </div>
  )
}

export default Nookipedia;
