import React, {useState, useEffect} from 'react';
import {Router, Redirect, Link}           from '@reach/router'

import {firebase, db} from './chatbox/firebase';
import Chatbox from './chatbox/Chatbox';
import Nookipedia from './nookipedia';
import Training from './training';

function App() {
  const user = useAuth();

  return (
    <Router>
      {user ? 
        (<Chatbox user={user} path="chatbox/*"/>) :
        (<Login path="chatbox/*"/>)
      }
      <Training path="/training" />
      <Nookipedia path="/"/>
      {/* <NavScreen path=""/> */}
    </Router>
  );
}

function NavScreen() {
  return (
    <div style={{width:'500px', margin: '100px auto'}}>
      <div>
        <Link to="chatbox">Chatbox</Link>
      </div>
      <div>
        <Link to="nookipedia">Nookipedia</Link>
      </div>
    </div>
  );
}

function Login() {
  const [authError, setAuthError] = useState(null);

  const handleSignIn = async () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    try {
      await firebase.auth().signInWithPopup(provider);
    }
    catch(err) {
      setAuthError(err);
    }
  }

  return (
    <div style={{width:'500px', margin: '100px auto'}}>
      <div className="login"> 
        {authError &&
          <div>
            <p>ERROR, Error, ERROR</p>
            <p>{authError.message}</p>
          </div>
        }
        <h1>Phocus</h1>
        <button onClick={handleSignIn}>Sign in with Google</button>
      </div>
    </div>
  );
}

function useAuth() {
  const [user, setUser] = useState(null);

  useEffect(()=>{
    firebase.auth().onAuthStateChanged((firebaseUser)=>{
      if(firebaseUser) {
        const user = {
          displayName : firebaseUser.displayName,
          photoUrl    : firebaseUser.photoURL,
          uid         : firebaseUser.uid
        }
        setUser(user);
        db.collection('USERS')
          .doc(user.uid)
          .set(user, {merge: true});
      }
      else {
        setUser(null);
      }
    });
  },[]);

  return user;
}

export default App;
