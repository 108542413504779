import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyD3yRXk_CLecP_bFdiVd4V0j9YbAqSp-iE",
  authDomain: "phocus-637a0.firebaseapp.com",
  databaseURL: "https://phocus-637a0.firebaseio.com",
  projectId: "phocus-637a0",
  storageBucket: "phocus-637a0.appspot.com",
  messagingSenderId: "302614638326",
  appId: "1:302614638326:web:2752dcffe49cac405b06d7"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
const db = firebase.firestore();
export { 
  db,
  firebase
};